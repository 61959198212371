import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'

export const DotLabelMain = styled.div`
  display: flex;
  align-items: center;
`

export const DotLabelIcon = styled.div`
  width: 1em;
  height: 1em;
  margin-right: 1em;
  ${clamp('font-size', 12, 15)}
  border-radius: 50%;
  background-color: ${colors.green};
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.6s ${easings.out.default} 0.3s;
`
