import React from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import { RoundedBackgroundMain, RoundedBackgroundInner } from './index.style'
import { colors } from '../../styles/vars/colors.style'

const RoundedBackground = ({ color, zIndex, children }) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <RoundedBackgroundMain ref={ref} zIndex={zIndex}>
      <RoundedBackgroundInner bg={color} show={inView}>
        {children && children}
      </RoundedBackgroundInner>
    </RoundedBackgroundMain>
  )
}

RoundedBackground.propTypes = {
  color: PropTypes.string,
  zIndex: PropTypes.number,
  children: PropTypes.node,
}

RoundedBackground.defaultProps = {
  color: colors.grey,
  zIndex: -1,
}

export default RoundedBackground
