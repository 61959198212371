import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { GridItemMain } from '../GridItem/index.style'

export const MiningMain = styled.div`
  padding-bottom: 9rem;

  ${GridItemMain}:first-child {
    position: relative;
  }
`

export const MiningImage = styled.div`
  position: relative;
  overflow: hidden;
  ${clamp('border-radius', 14, 20)}
  background-color: ${colors.light2};
`

export const MiningImageDots = styled.div`
  position: absolute;
  top: 8.7%;
  right: 8.7%;
  bottom: 8.7%;
  left: 8.7%;
`

export const MiningContent = styled.div`
  ${mq.tabletPMax} {
    margin-top: 4rem;
  }

  ${mq.tabletP} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`

export const MiningDot = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  transform: translateX(-50%);
  display: none;

  ${mq.tabletL} {
    display: block;
  }
`

export const MiningIcon = styled.div`
  position: absolute;
  ${clamp('left', 20, 30)}
  ${clamp('bottom', 20, 30)}
  ${clamp('width', 35, 60)}
  background: ${colors.light};
  border: 0.1rem solid ${colors.grey};
  border-radius: 50%;
  box-shadow: 0px 1.93548px 1.93548px rgba(208, 207, 200, 0.51),
    0px 3.87097px 3.87097px rgba(208, 207, 200, 0.4),
    inset 0px -3.87097px 3.87097px rgba(209, 208, 200, 0.2),
    inset 0px 3.87097px 3.87097px rgba(255, 255, 255, 0.25);
`
