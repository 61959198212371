import React from 'react'
import queryString from 'query-string'
import { graphql } from 'gatsby'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

// Components
import Seo from '../components/Seo'
import Hero from '../components/Products/Hero'
import Features from '../components/Products/Features'
import Security from '../components/Products/Security'
import Index from '../components/Products/Index'
import Mining from '../components/Mining'
import Spacer from '../components/Spacer'

// Fragments
import ContentfulListItems from '../fragments/ContentfulListItems' // eslint-disable-line no-unused-vars
import ContentfulLink from '../fragments/ContentfulLink' // eslint-disable-line no-unused-vars
import { useEffect } from 'react'

gsap.registerPlugin(ScrollToPlugin)

const Products = ({ data, location }) => {
  const {
    seoTitle,
    seoDescriptions,
    slug,
    heroLabel,
    heroTitle,
    heroText,
    heroImage,
    productFeatures,
    securityTitle,
    securityText,
    indexes,
    miningTitle,
    miningText,
    miningCtaText,
    miningCtaTarget,
    miningImage,
  } = data.contentfulProducts

  useEffect(() => {
    const { index } = queryString.parse(location.search)

    if (index) {
      gsap.to(window, {
        scrollTo: `#${index}`,
        duration: 2,
      })
    }
  }, [location.search])

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescriptions?.seoDescriptions}
        pathname={slug}
      />

      <Hero
        label={heroLabel}
        title={heroTitle}
        text={heroText}
        image={heroImage}
      />

      <Features features={productFeatures} />

      <Security title={securityTitle} text={securityText} />

      {React.Children.toArray(
        indexes.map((item, itemIndex) => (
          <Index {...item} last={indexes.length - 1 === itemIndex} />
        ))
      )}
      <Spacer size={50} />

      <Mining
        title={miningTitle}
        text={miningText}
        ctaText={miningCtaText}
        ctaTarget={miningCtaTarget}
        image={miningImage}
      />
    </>
  )
}

export default Products

export const pageQuery = graphql`
  query Products($slug: String!) {
    contentfulProducts(slug: { eq: $slug }) {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      slug
      heroLabel
      heroTitle
      heroText {
        raw
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
        description
      }
      productFeatures {
        ... on Node {
          ...ContentfulListItems
        }
      }
      securityTitle
      securityText {
        raw
      }
      indexes {
        ... on ContentfulEmberIndex {
          contentfulid
          title
          descriptionOptional {
            raw
          }
          data {
            ... on Node {
              ...ContentfulListItems
            }
          }
        }
      }
      miningTitle
      miningText {
        raw
      }
      miningCtaText
      miningCtaTarget {
        ... on Node {
          ...ContentfulLink
        }
      }
      miningImage {
        gatsbyImageData(quality: 100)
        description
      }
    }
  }
`
