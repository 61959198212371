import React from 'react'
import PropTypes from 'prop-types'
import {
  MiningContent,
  MiningDot,
  MiningIcon,
  MiningImage,
  MiningImageDots,
  MiningMain,
} from './index.style'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import { Heading4 } from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import Spacer from '../Spacer'
import RichText from '../RichText'
import LinkButton from '../LinkButton'
import IconMining from '../svgs/IconMining'
import DotHalf from '../svgs/DotHalf'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import Dots from '../Dots'
import AnimateFadeIn from '../animation/AnimateFadeIn'

const Mining = ({ title, text, ctaText, ctaTarget, image, icon }) => (
  <MiningMain>
    <Container>
      <Grid>
        <GridItem tabletP={8} tabletPOrder={2} tabletL={8} tabletLStart={9}>
          <MiningImage>
            <MiningImageDots>
              <Dots
                showBackgroundDots={false}
                shimmer={false}
                lerpOpacityScale={0.5}
              />
            </MiningImageDots>

            <AnimateFadeIn>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.description}
              />
            </AnimateFadeIn>

            {icon && (
              <MiningIcon>
                <AnimateSlideIn delay={0.1}>
                  <IconMining />
                </AnimateSlideIn>
              </MiningIcon>
            )}
          </MiningImage>

          <MiningDot>
            <AnimateSlideIn>
              <DotHalf />
            </AnimateSlideIn>
          </MiningDot>
        </GridItem>

        <GridItem
          tabletP={8}
          tabletPOrder={1}
          tabletL={5}
          tabletLStart={2}
          tabletLOrder={1}
        >
          <MiningContent>
            <Heading4 as="h3" maxWidth={11.09}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading4>

            <Spacer size={[20, 35]} />

            <RichText content={text} paragraphSize="large" maxWidth={19.62} />

            {ctaText && ctaTarget && (
              <>
                <Spacer size={[40, 60]} />
                <LinkButton
                  text={ctaText}
                  target={ctaTarget}
                  variant="secondary"
                />
              </>
            )}
          </MiningContent>
        </GridItem>
      </Grid>
    </Container>
  </MiningMain>
)

Mining.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  ctaText: PropTypes.string,
  ctaTarget: PropTypes.object,
  image: PropTypes.object,
  icon: PropTypes.bool,
}

Mining.defaultProps = {
  icon: true,
}

export default Mining
