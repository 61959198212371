import React from 'react'

const IconMining = ({
  width = 60,
  height = 60,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M21.738 19.227a.968.968 0 0 0 .429 1.887l-.43-1.887Zm15.977 17.435a.968.968 0 0 0 1.887.43l-1.887-.43Zm-4.886-9.294a.968.968 0 1 0-1.38-1.356l1.38 1.356ZM19.632 38.032a.968.968 0 0 0 1.38 1.357l-1.38-1.357Zm17.569-17.77a.968.968 0 1 0 1.285 1.447l-1.285-1.447Zm3.119-.183a.968.968 0 1 0-1.286-1.447l1.286 1.447Zm-18.153 1.035c4.183-.95 8.93.198 12.14 3.408l1.368-1.369c-3.716-3.716-9.156-5.013-13.937-3.926l.429 1.887Zm12.14 3.408c3.21 3.21 4.358 7.957 3.408 12.14l1.887.43c1.087-4.782-.21-10.222-3.926-13.939l-1.37 1.369Zm-2.858 1.49-11.817 12.02 1.38 1.357 11.817-12.02-1.38-1.357Zm7.037-4.303 1.834-1.63-1.286-1.447-1.833 1.63 1.285 1.447Z"
      />
    </svg>
  )
}

export default IconMining
