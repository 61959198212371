import React from 'react'
import PropTypes from 'prop-types'
import Spacer from '../../Spacer'
import { Heading4 } from '../../TextStyles'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import RichText from '../../RichText'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { SecurityMain, SecurityContent, SecurityImage } from './index.style'
import SecurityIllustration from '../../svgs/Security'

const Security = ({ title, text }) => (
  <SecurityMain>
    <Spacer size={[0, 250]} />
    <Container>
      <Grid>
        <GridItem
          tabletP={12}
          tabletPStart={2}
          tabletL={5}
          tabletLStart={2}
          tabletLAlign={`center`}
        >
          <SecurityContent>
            <Spacer size={80} />
            <Heading4>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading4>
            <Spacer size={[20, 35]} />
            <RichText content={text} />
            <Spacer size={80} />
          </SecurityContent>
        </GridItem>
        <GridItem
          tabletP={11}
          tabletPStart={4}
          tabletL={7}
          tabletLStart={9}
          tabletLAlign={`center`}
        >
          <SecurityImage>
            <SecurityIllustration />
          </SecurityImage>
        </GridItem>
      </Grid>
    </Container>
    <Spacer size={[80, 250]} />
  </SecurityMain>
)

Security.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
}

export default Security
