import React from 'react'
import PropTypes from 'prop-types'
import Spacer from '../../Spacer'
import { Heading2, Label } from '../../TextStyles'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import RichText from '../../RichText'
import RoundedBackground from '../../RoundedBackground'
import AnimateSplitText from '../../animation/AnimateSplitText'
import {
  IndexMain,
  IndexContent,
  IndexDetails,
  IndexList,
  IndexItem,
} from './index.style'

const Index = ({ contentfulid, title, descriptionOptional, data, last }) => (
  <>
    <IndexMain id={contentfulid}>
      <RoundedBackground />
      <Spacer size={[90, 200]} />
      <Container>
        <Grid>
          <GridItem tabletP={14} tabletPStart={2} tabletL={5} tabletLStart={2}>
            <IndexContent>
              <Heading2>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading2>
              <Spacer size={20} />
              {descriptionOptional && (
                <>
                  <RichText content={descriptionOptional} />
                  <Spacer size={[15, 20]} />
                </>
              )}
              <Spacer size={[15, 20]} />
            </IndexContent>
          </GridItem>
          <GridItem tabletP={14} tabletPStart={2} tabletL={7} tabletLStart={9}>
            <IndexDetails>
              <IndexList>
                {React.Children.toArray(
                  data.map((item, itemIndex) => (
                    <IndexItem>
                      <Label>
                        <AnimateSplitText delay={itemIndex * 0.1}>
                          {item.title}
                        </AnimateSplitText>
                      </Label>
                      <Label>
                        <AnimateSplitText delay={itemIndex * 0.1}>
                          {item.content}
                        </AnimateSplitText>
                      </Label>
                    </IndexItem>
                  ))
                )}
              </IndexList>
            </IndexDetails>
          </GridItem>
        </Grid>
      </Container>
      <Spacer size={[70, 200]} />
    </IndexMain>
    {!last && <Spacer size={[5, 50]} />}
  </>
)

Index.propTypes = {
  title: PropTypes.string,
  descriptionOptional: PropTypes.object,
  data: PropTypes.array,
  last: PropTypes.bool,
}

export default Index
