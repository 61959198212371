import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { Label } from '../../../styles/vars/textStyles.style'

export const IndexMain = styled.div`
  position: relative;
`

export const IndexContent = styled.div``

export const IndexDetails = styled.div``

export const IndexList = styled.ul`
  list-style-type: none;
`

export const IndexItem = styled.li`
  ${clamp('padding-bottom', 35, 40)};
  ${clamp('padding-top', 35, 40)};
  position: relative;
  width: 100%;

  :last-child {
    padding-bottom: 0;
  }

  ${mq.tabletPMax} {
    ${Label}:last-child {
      margin-top: 1.2rem;
    }
  }

  ${mq.tabletP} {
    display: flex;
    justify-content: space-between;
  }

  :before {
    background-color: ${colors.dark};
    height: 0.1rem;
    content: '';
    left: 0;
    opacity: 0.2;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 0% 0%;
  }
`
