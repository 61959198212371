import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useStore } from '../../Store'
import PropTypes from 'prop-types'
import { DotLabelIcon, DotLabelMain } from './index.style'
import AnimateSlideIn from '../animation/AnimateSlideIn'

const DotLabel = ({ animate = true, children }) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [store] = useStore()
  const { showPageMask } = store

  return (
    <DotLabelMain ref={ref}>
      <DotLabelIcon show={inView && animate && !showPageMask} />
      <AnimateSlideIn animate={animate}>{children}</AnimateSlideIn>
    </DotLabelMain>
  )
}

DotLabel.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default DotLabel
