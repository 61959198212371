import React from 'react'

const Security = ({
  width = 705,
  height = 705,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 705 705"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      {/* Temporary - will be replaced with Lottie */}
      <circle
        cx="354.5"
        cy="354.5"
        r="349"
        stroke="#00DE3E"
        strokeWidth="3"
        strokeDasharray="3 15"
      />
      <circle
        cx="354.5"
        cy="354.5"
        r="214"
        stroke="#1D1A23"
        strokeWidth="3"
        strokeDasharray="3 15"
      />
      <circle
        cx="354.5"
        cy="354.5"
        r="100"
        stroke="#00DE3E"
        strokeWidth="3"
        strokeDasharray="3 15"
      />
      <circle cx="372.5" cy="4.5" r="4.5" fill="#00DE3E" />
      <circle cx="542.5" cy="455.5" r="4.5" fill="#00DE3E" />
      <circle cx="267.5" cy="404.5" r="4.5" fill="#00DE3E" />
      <circle cx="276.5" cy="289.5" r="4.5" fill="#00DE3E" />
      <circle cx="252.5" cy="165.5" r="4.5" fill="#1D1A23" />
      <circle cx="499.5" cy="197.5" r="7.5" fill="#1D1A23" />
      <circle cx="440.5" cy="404.5" r="7.5" fill="#1D1A23" />
      <circle cx="620.5" cy="581.5" r="7.5" fill="#00DE3E" />
      <circle cx="140.5" cy="354.5" r="4.5" fill="#00DE3E" />
      <circle cx="4.5" cy="354.5" r="4.5" fill="#00DE3E" />
      <circle cx="21.5" cy="248.5" r="4.5" fill="#00DE3E" />
      <circle cx="114.5" cy="607.5" r="4.5" fill="#1D1A23" />
      <circle cx="650.5" cy="169.5" r="4.5" fill="#1D1A23" />
      <circle cx="355" cy="355" r="22" fill="#00DE3E" />
    </svg>
  )
}

export default Security
