import React from 'react'
import PropTypes from 'prop-types'
import Spacer from '../../Spacer'
import { Heading2 } from '../../TextStyles'
import Container from '../../Container'
import Grid from '../../Grid'
import Dots from '../../Dots'
import GridItem from '../../GridItem'
import RichText from '../../RichText'
import RoundedBackground from '../../RoundedBackground'
import AnimateSplitText from '../../animation/AnimateSplitText'
import {
  FeaturesMain,
  FeaturesList,
  FeaturesItem,
  FeaturesDots,
} from './index.style'

const Features = ({ features }) => (
  <FeaturesMain>
    <RoundedBackground />

    <Spacer size={[80, 250]} />

    <Container>
      <Grid>
        <GridItem
          tabletPOrder={2}
          tabletP={9}
          tabletPStart={8}
          tabletL={7}
          tabletLStart={9}
        >
          <FeaturesList>
            {React.Children.toArray(
              features.map((item, itemIndex) => (
                <FeaturesItem>
                  {itemIndex > 0 && <Spacer size={[35, 50]} />}

                  <Heading2 maxWidth={10}>
                    <AnimateSplitText delay={itemIndex * 0.1}>
                      {item.title}
                    </AnimateSplitText>
                  </Heading2>

                  <Spacer size={[20, 30]} />

                  <RichText
                    content={item.richContent}
                    delay={itemIndex * 0.1}
                    maxWidth={25}
                  />

                  {itemIndex < features.length - 1 && (
                    <Spacer size={[35, 50]} />
                  )}
                </FeaturesItem>
              ))
            )}
          </FeaturesList>
        </GridItem>

        <GridItem
          tabletPOrder={1}
          tabletP={5}
          tabletPStart={2}
          tabletL={6}
          tabletLStart={2}
        >
          <FeaturesDots>
            <Dots shimmerAmount={3} />
          </FeaturesDots>
        </GridItem>
      </Grid>
    </Container>
    <Spacer size={[45, 200]} />
  </FeaturesMain>
)

Features.propTypes = {
  features: PropTypes.array.isRequired,
}

export default Features
