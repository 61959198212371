import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { GridItemMain } from '../../GridItem/index.style'

export const FeaturesMain = styled.div`
  position: relative;

  ${GridItemMain}:last-child {
    position: relative;
  }
`

export const FeaturesList = styled.ul`
  list-style-type: none;
`

export const FeaturesDots = styled.div`
  ${mq.tabletPMax} {
    position: relative;
    height: 6rem;
  }

  ${mq.tabletP} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const FeaturesItem = styled.li`
  position: relative;

  :before {
    background-color: ${colors.dark};
    bottom: 0;
    height: 0.1rem;
    content: '';
    left: 0;
    opacity: 0.2;
    position: absolute;
    right: 0;
    transform-origin: 0% 0%;
  }

  :last-child:before {
    display: none;
  }
`
