import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'
import { matchContainerPadding } from '../../Container/index.style'

export const HeroMain = styled.div`
  position: relative;
`

export const HeroWrapper = styled.div`
  position: relative;
`

export const HeroContent = styled.div`
  position: relative;
  z-index: 1;
`

export const HeroDot = styled.div`
  position: absolute;
  display: none;

  :nth-child(1) {
    top: -6rem;
    left: 0;
    transform-origin: 0% 100%;
    transform: rotate(90deg) translateX(-100%);

    ${mq.tabletL} {
      display: block;
    }
  }

  :nth-child(2) {
    top: 16%;
    right: 0;
    transform-origin: 100% 100%;
    transform: rotate(-90deg);

    ${mq.deskL} {
      display: block;
    }
  }
`

export const HeroImage = styled.div`
  position: relative;
`

export const HeroText = styled.div`
  max-width: 56rem;
`

export const HeroImageDot = styled.div`
  position: absolute;

  :nth-child(2) {
    top: 18%;
    ${matchContainerPadding('left', true)}
    transform: translateX(-50%);

    ${mq.tabletL} {
      top: 6%;
      left: 8%;
      transform: translateX(0);
    }
  }

  :nth-child(3) {
    top: 50%;
    ${matchContainerPadding('right', true)}
    transform-origin: 100% 100%;
    transform: rotate(-90deg);

    ${mq.tabletL} {
      top: 13%;
    }

    ${mq.deskL} {
      display: none;
    }
  }
`
