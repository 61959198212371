import styled from 'styled-components'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { matchContainerPadding } from '../Container/index.style'

export const RoundedBackgroundMain = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  z-index: ${props => props.zIndex};

  ${mq.desk} {
    ${matchContainerPadding('padding-left')}
    ${matchContainerPadding('padding-right')}
  }
`

export const RoundedBackgroundInner = styled.div`
  position: relative;
  background-color: ${props => props.bg};
  border-radius: 0;
  height: 100%;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s ${easings.inOut.default};
  width: 100%;

  ${mq.desk} {
    border-radius: 1.5rem;
  }
`
